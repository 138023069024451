<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active':sidebarActive,
                                        'layout-overlay-menu':overlay}">
    <!--topbar-->
    <app-home-top></app-home-top>
    <!--sidebar-->
    <app-home-sidebar></app-home-sidebar>
    <div class="layout-content">
        <div class="layout-content-inner h-full">
             <router-outlet></router-outlet>
        </div>
    </div>
</div>

<audio id="audioChat" controls style="display: none;">
    <source type="audio/wav" src="assets/sounds/chat.mp3">
</audio>

<audio id="audioNotify" controls style="display: none;">
    <source type="audio/wav" src="assets/sounds/notification.mp3">
</audio>