import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { GlobalService } from '../../../services/global.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionIn } from 'src/app/interfaces/session-in';
import { Subscription, firstValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/services/company.service';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HubService } from 'src/app/services/hub.service';
import { SocketService } from 'src/app/services/socket.service';
import { OnDestroy } from '@angular/core';
@Component({
  selector: 'app-home-top',
  templateUrl: './home-top.component.html',
  styleUrls: ['./home-top.component.scss'],
  providers: [ConfirmationService],
})
export class HomeTopComponent implements OnInit, OnDestroy {
  sessionIn: SessionIn;
  type = 'USER';
  theme: string;
  lang: string;
  themeStatus: boolean = false;
  langStatus: boolean = false;
  topbarItemClick: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: any;
  business = false;
  notifications = false;
  quantityNotifications = 0;
  logo_ramdon = 12;
  sidebarMobile = false;
  @Output() showNot = new EventEmitter<Boolean>();
  menuOpen: boolean = true;
  menuBtnClick: boolean = false;
  //search
  formSearch :UntypedFormGroup;
  types = [{ label: 'Empresas', value: 'company' }];
  fields = [];
  feedback = false;
  quantityNotificationsChat = 0;
  sidebarSearch = false;
  //responsive 
  desktop = true;
  //chat user
  companiesChat = [];
  loadCompaniesChat = false;
  suscriptionWssChat: Subscription;
  typeRedirectChat = "";
  chat = false;
  subscriber: Subscription;
  accessDirect = "";
  constructor(
    private globalService: GlobalService,
    private confirmationService: ConfirmationService,
    private sessionService: SessionService,
    private router: Router,
    private companyService: CompanyService,
    private hubService: HubService,
    private aws: AwsSharedService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    public trans: TranslateService,
    private fb: UntypedFormBuilder,
    private wssService: SocketService
  ) {
    this.desktop = this.globalService.desktop;
    this.translateService();
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateService();
            break;
          case 'UPDATECARD':
            this.sessionIn = this.sessionService.sessionIn;
            this.logo_ramdon = Math.random();
            break;
          case 'CLOSESIDEBARNOTIFICATIONS':
            this.notifications = false;
            break;
          case 'UPDATENOTIFICATIONS':
            this.getQuantityNotificationsNotShow();
            break;
          case 'SIDEBAR_TYPE_REFRESH':
            if(res['status'] === "COMPANY"){
              this.resetNameCompany(res['status']);
            }else if(res['status'] === "HUB"){
              this.resetNameCompany(res['status']);
            }else{
              this.type = 'USER';
            }
            break;
          case 'UPDATE_COUNT_CHAT':
            this.getChat();
            break;
          case 'OPEN_INVITATIONS':
            this.notifications = true;
            break;
          default:
            break;
        }
      }
    });
    this.suscriptionWssChat = this.wssService.chatMessage$.subscribe(data => {
      if(data){
        switch (data['action']) {
          case "chatParticipantRemove":
            this.getChat();
            break;
          case "chatParticipantUpdate":
            this.getChat();
            break;
          default:
            break;
        }
      }
    });
    this.sessionIn = this.sessionService.sessionIn;
    this.renderer.listen('window', 'click', (e) => {
      if(!this.showInputSearch){
        //validar si el click viene desde el boton
        if(!this.menuBtnClick){
          let block = document.getElementById('formularySearchOpen');
          if (block){
            //console.log(block.clientHeight, block.clientWidth, block.offsetLeft, block.offsetTop)
            let statusX = e.clientX >= block.offsetLeft && e.clientX <= (block.clientWidth + block.offsetLeft) ? true : false;
            let statusY = e.clientY >= block.offsetTop && e.clientY <= (block.clientHeight + block.offsetTop)
            if(statusX && statusY){
              //console.log("clic dentro")
            }else{
              let query = this.formSearch.value && this.formSearch.value.query ? this.formSearch.value.query.trim() : "";
              let fields = this.formSearch.value && this.formSearch.value.fields.length > 0 ? this.formSearch.value.fields : [];
              if(!(query.length > 0 || fields.length > 0)){
                this.menuOpen = false;
              }
            }
          }
        }else{
          this.menuBtnClick = false;
        }
      }
    });
    this.formSearch = this.fb.group({
      query: [ '', Validators.compose([Validators.required, Validators.minLength(4)])],
      //type: 'company',
      fields: ''
    });
  }

  async translateService() {
    let fields :any = await new Promise((resolve, reject) => {
      this.trans.get('multi_select_search').subscribe((res) => {
        resolve(res);
      });
    });

    this.fields = [
      { label: fields['business_name'], value: 'business_name' }, 
      { label: fields['trade_name'], value: 'trade_name' }, 
      { label: fields['code_tributary'], value: 'code_tributary' },
      { label: fields['country_text'], value: 'country_text' }, 
      { label: fields['city_text'], value: 'city_text' }, 
      { label: fields['state_text'], value: 'state_text' }, 
      { label: fields['comercial_activity_text'], value: 'comercial_activity_text' },
      { label: fields['commercial_section_text'], value: 'commercial_section_text' }, 
      { label: fields['categories_text'], value: 'categories_text' }, 
      { label: fields['sub_groups_text'], value: 'sub_groups_text' }, 
      { label: fields['groups_text'], value: 'groups_text' },
      { label: fields['segments_text'], value: 'segments_text' }, 
      { label: fields['categories_unspsc_text'], value: 'categories_unspsc_text' }, 
      { label: fields['sub_groups_unspsc_text'], value: 'sub_groups_unspsc_text' }, 
      { label: fields['groups_unspsc_text'], value: 'groups_unspsc_text' }, 
      { label: fields['segments_unspsc_text'], value: 'segments_unspsc_text' }
  ];

  }

  ngOnInit(): void {
    this.theme = localStorage.getItem('theme');
    this.lang = localStorage.getItem('lang');
    if (localStorage.getItem('theme') === 'light') {
      this.themeStatus = false;
    } else if (localStorage.getItem('theme') === 'dark') {
      this.themeStatus = true;
    }

    if (localStorage.getItem('lang') === 'es') {
      this.langStatus = false;
    } else if (localStorage.getItem('lang') === 'en') {
      this.langStatus = true;
    }

    this.getQuantityNotificationsNotShow();

    let pathName = window.location.pathname;
    if(['/home/search/company'].includes(pathName)){
      this.menuOpen = true;
      let fields = this.route.snapshot.queryParamMap.get("fields") && this.route.snapshot.queryParamMap.get("fields").length > 0 ? this.route.snapshot.queryParamMap.get("fields").split(",") : ['business_name', 'trade_name', 'categories_text'];
      this.formSearch.patchValue({ query: this.route.snapshot.queryParamMap.get("q"), fields: fields })
    }else{
      this.formSearch.patchValue({ fields: ['business_name', 'trade_name', 'categories_text'] })
    }
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.suscriptionWssChat?.unsubscribe();
  }

  async resetNameCompany(type) {
    let idCompany = type === "HUB" ? this.hubService.idCompany : this.companyService.idCompany;
    let result = await firstValueFrom( this.aws.getCompany({ idCompany: idCompany }) );
    if (result['status'] === false) {
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST' });
    } else if (result['status'] === true) {
      this.type = result['data']['company']['businessName'];
      // consultar logica
      
    } else {
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST' });
    }
  }

  async getQuantityNotificationsNotShow() {
    let result = await firstValueFrom(
      this.aws.getNotificationsNotShow()
    );
    this.quantityNotifications =
      result && result['status'] ? result['data'] : 0;
  }

  onTopbarMenuButtonClick(event: Event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    event.preventDefault();
  }

  themeChange() {
    let theme = this.themeStatus ? 'dark' : 'light';
    localStorage.setItem('theme', theme);
    this.globalService.switchTheme(theme);
    this.updatePreference();
    this.globalService.sendRequest({ type: 'UPDATETHEME' });
  }

  langChange() {
    this.lang = this.langStatus ? 'en' : 'es';
    localStorage.setItem('lang', this.lang);
    this.globalService.changeLanguage(this.lang);
    this.updatePreference();
    this.globalService.sendRequest({ type: 'UPDATELANG' });
  }

  async updatePreference() {
    let update = (await new Promise((resolve, reject) => {
      this.aws
        .updatePreference({
          theme: this.themeStatus ? 'dark' : 'light',
          language: this.lang,
        })
        .subscribe(
          (next) => resolve(next),
          (error) => reject(error)
        );
    })) as any;
  }

  confirmSignOff(event: Event) {
    this.confirmationService.confirm({
      message:
        this.lang === 'es'
          ? '¿Está seguro que desea cerrar sesión? '
          : 'Are you sure you want to log out?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.sidebarMobile = false;
        this.signOff();
      },
    });
  }

  async signOff() {
    let logout = (await new Promise((resolve, reject) => {
      this.aws.logout().subscribe(
        (next) => resolve(next),
        (error) => reject(error)
      );
    })) as any;
    this.sessionService.deletedSession();
    localStorage.removeItem('theme');
    localStorage.removeItem('lang');
    localStorage.removeItem('url');
    window.location = window.location;
  }

  openProfile() {
    this.sidebarMobile = false;
    if(this.companyService.idCompany){
      this.router.navigate([`/home/company/${this.companyService.idCompany}/user-profile`]);
    }else{
      this.router.navigate(['/home/my-account']);
    }
  }

  //notificaciones
  async openNotifications() {
    this.notifications = true;
    if (this.quantityNotifications > 0) {
      let result = await firstValueFrom( this.aws.updateNotificationsToTrue() );
      if (result && result['status']){
        this.quantityNotifications = 0;
      }
    }
  }

  closeSidebarEvent() {
    this.business = false;
  }

  get showInputSearch(){
    let pathName = window.location.pathname;
     return ['/home/search/company'].includes(pathName) ? true : false;
  }

  //buscador
  onSubmit(){
    if(this.formSearch.valid){
      let fields = this.formSearch.value.fields && this.formSearch.value.fields.length > 0 ? this.formSearch.value.fields.join(",") : "business_name,trade_name,categories_text";
      let typeSession = this.globalService.getTypeSession();
      if(typeSession.idCompany != "NOT_COMPANY" && typeSession.idCompany.length > 11){
        this.router.navigate(['/home/search/company'], { queryParams: { q: this.formSearch.value.query.trim(), company: typeSession.idCompany, fields: fields } });
      }else{
        this.router.navigate(['/home/search/company'], { queryParams: { q: this.formSearch.value.query.trim(), fields: fields } });
      }
      this.sidebarSearch = false;
    }
  }

  async goChat(){
    //validar el tipo: COMPANY, USER
    let type = this.getTypeUser();
    this.typeRedirectChat = "";
    if(type === "COMPANY"){
      //abrir overlay panel
      this.chat = true;
      this.typeRedirectChat = "COMPANY";

      this.loadCompaniesChat = true;
      let result = await firstValueFrom(this.aws.listChatParticipantCompanyUnread({ idCompany: this.companyService.idCompany }));
      this.loadCompaniesChat = false;
      if (result && result['status']){
        this.companiesChat = result['data'] ? result['data'] : [];
      }else{
        this.companiesChat = [];
      }
    }else{
      //abrir overlay panel
      this.chat = true;
      this.loadCompaniesChat = true;
      let result = await firstValueFrom(this.aws.listChatParticipantUnreadAll({}));
      this.loadCompaniesChat = false;
      if (result && result['status']){
        this.companiesChat = result['data'] ? result['data'] : [];
      }else{
        this.companiesChat = [];
      }
    }
  }

  async getChat(){
    //validar el tipo: COMPANY, USER
    let type = this.getTypeUser();
    if(type === "COMPANY"){
      let result = await firstValueFrom(this.aws.quantityMessageUnreadChatCompany({ idCompany: this.companyService.idCompany }));
      if (result && result['status']){
        this.quantityNotificationsChat = result['data'] ? result['data']['messagesToRead'] : 0;
      }
    }else{
      let result = await firstValueFrom(this.aws.quantityMessageUnreadChatAll({}));
      if (result && result['status']){
        this.quantityNotificationsChat = result['data'] ? result['data']['messagesToRead'] : 0;
      }
    }
  }

  async goChatRedirect(participant){
    if(["COMPANY", "USER_GROUP_COMPANY", "USER_DIRECT_COMPANY"].includes(participant['typeChat'])){
      if(participant['newMessageUrl'].length > 0){
        this.router.navigate([participant['newMessageUrl']]);
      }else{
        this.router.navigate([`/home/company/${participant.idCompany}/messages/f/${participant.idChat}`]);
      }
      if(screen.width < 1024){
        setTimeout(() => {
          if(participant.newMessageUrl.includes('thread')){
            this.globalService.sendRequest({ type: 'OPENCONTAINERCHATRESPONSE' }); 
          }else{
           this.globalService.sendRequest({ type: 'OPENCONTAINERCHAT' }); 
          }
        }, 2000);
      }
    }
  }

  goChatCompany(){
    let type = this.getTypeUser();
    if(type === "COMPANY"){
      this.router.navigate([`/home/company/${this.companyService.idCompany}/messages/f`]);
      this.globalService.sendRequest({ type: 'SELECTED_CHAT' , data: null, tab: "ALL" });
    }
  }

  getTypeUser(){
    let sidebarType = "USER";
    let paths = this.router.url.split("/").filter(c => c.length > 0 );
    if(paths.length > 0){
      if(paths[1] === "company"){
        if(this.companyService.idCompany.length > 0){
          sidebarType = "COMPANY";
        }
      }
    }
    return sidebarType;
  }

  openMenu(){
    this.globalService.sendRequest({type : 'OPENSIDEBARMOBILE'});
  }
}
