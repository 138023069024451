import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PrimengModule } from './modules/primeng/primeng.module';
import { HomeSidebarComponent } from 'src/app/components/home/home-sidebar/home-sidebar.component';
import { HomeTopComponent } from 'src/app/components/home/home-top/home-top.component';
import { HomeComponent } from './pages/home/home.component';
import { GlobalModule } from './modules/global/global.module';
import { CompanyComponent } from './pages/home/company/company.component';
import { HubComponent } from './pages/home/hub/hub.component'
// import { ChatComponent } from './pages/home/chat/chat.component';
import { NoPageFoundComponent } from './pages/no-page-found/no-page-found.component';
// import { HowDoesItWorkComponent } from './pages/home/how-does-it-work/how-does-it-work.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BilledXmlComponent } from './pages/external/billed-xml/billed-xml.component';
import { ExampleComponent } from './pages/home/example/example.component';
import { MsalInterceptor, MsalInterceptorConfiguration, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { CustomRouteReuseStrategy } from './shared/custom-route-reuse-strategy';

export function HttpLoaderFactory( http: HttpClient ){
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const pca = new PublicClientApplication({
  auth: {
    clientId: '1e46f6ee-326c-4839-8cde-8fc2661e90dc',
    authority: 'https://login.microsoftonline.com/e326d54a-d79f-422f-8253-f4278c0b97b3',
    redirectUri: environment.redirectUriDivemotor
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    allowNativeBroker: false, // Disables native brokering support
  }
});

pca.initialize();

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Popup, // Evita redirecciones usando popup
    protectedResourceMap: new Map([
      ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
    ]),
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeSidebarComponent,
    HomeTopComponent,
    CompanyComponent,
    HubComponent,
    // ChatComponent,
    NoPageFoundComponent,
    BilledXmlComponent,
    ExampleComponent
    // HowDoesItWorkComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PrimengModule,
    GlobalModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register ('ngsw-worker.js', {enabled: environment.production, registrationStrategy: 'registerImmediately'}),
    MsalModule.forRoot(
      pca,
      {
        interactionType: InteractionType.Popup,
        authRequest: {
          scopes: ['User.Read'],
        },
      },
      MSALInterceptorConfigFactory()
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
