<div class="layout-wrapper" >
    <div class="topbar clearfix">

    </div>
    <div class="layout-content ml-0">
        <div class="layout-content-inner h-full">
            <div class="grid m-0 scrollStyle justify-content-center" style="height: calc(var(--doc-height) - 62px); overflow-x: hidden; overflow-y: auto;">
                <div class="card" style="width: 900px;">
                    <div class="card shadow-5 m-0">
                        <div class="grid m-0 text-center">
                            <div class="col-12 pt-4 pb-4">
                                <img style="max-width: 400px; height: 120px;  object-fit: cover;" src="https://cbn-files-business.s3.amazonaws.com/94dcc9cc72900c0c608cf987035a778c/profile/logo/eb0bd6c9-8ef6-44c4-9a9b-33571e2d203d.png?e=12" alt="" srcset="">
                            </div>
                            <div class="col-12">
                                <h3 class="m-0 text-2xl" >CONSULTA INTEGRADA DE COMPROBANTE DE PAGO</h3>
                            </div>
                            <div class="col-12 p-0 flex align-items-center justify-content-center">
                                <div style="width: 200px;">
                                  <p-divider />  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="grid">
                                    <div class="field col-12">
                                        <p-fileUpload 
                                            name="demo[]" 
                                            url="https://www.primefaces.org/cdn/api/upload.php" 
                                            (onUpload)="onUpload($event)" 
                                            [multiple]="true" 
                                            accept=".xml, .XML" 
                                            maxFileSize="1000000">
                                                <ng-template pTemplate="content">
                                                    <div  *ngIf="loadData" class="card w-full m-0 mb-2 p-3 text-left">
                                                        Cargando ...
                                                    </div>
                                                    <div  *ngFor="let file of uploadedFiles" class="card w-full m-0 mb-2 p-3 text-left">
                                                        <div class="grid m-0">
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Ruc Proveedor: </b> {{file.numRuc}}</label>
                                                            </div>
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Ruc Cliente: </b> {{file.numRucClient}}</label>
                                                            </div>
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Tipo de comprobante: </b> {{file.codComp}}</label>
                                                            </div>
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Correlativo de serie - documento: </b> {{file.numeroSerie}}-{{file.numero}}</label>
                                                            </div>
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Fecha de emisión: </b> {{file.fechaEmision}}</label>
                                                            </div>
                                                            <div class="field mb-1 col-12 p-0">
                                                                <label><b>Valor total: </b> {{file.currency}} {{file.monto}}</label>
                                                            </div>
                                                            @if(file['statusValid']){
                                                                <div class="field mb-1 col-12 p-0">
                                                                    <label><b style="color: cornsilk">Estado del comprobante: </b> {{file.estadoCp}} - {{file.estadoCpDescripcion}}</label>
                                                                </div>
                                                                <div class="field mb-1 col-12 p-0">
                                                                    <label><b style="color: cornsilk">Estado del contribuyente: </b> {{file.estadoRuc}} - {{file.estadoRucDescripcion}}</label>
                                                                </div>
                                                                <div class="field mb-1 col-12 p-0">
                                                                    <label><b style="color: cornsilk">Condición de Domicilio del Contribuyente: </b> {{file.condDomiRuc}} - {{file.condDomiRucDescripcion}}</label>
                                                                </div>
                                                                <div class="field mb-1 col-12 p-0">
                                                                    <label><b style="color: cornsilk">Observaciones: </b> {{file.observaciones}}</label>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                        </p-fileUpload>
                                        <!-- <p-fileUpload 
                                            mode="basic" 
                                            chooseLabel="Seleccionar" 
                                            chooseIcon="pi pi-upload"
                                            maxFileSize="1000000" 
                                            (onSelect)="onSelect($event)"
                                            [auto]="true"
                                            /> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>