import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import * as uuid from 'uuid';

enum MenuOrientation {
  STATIC,
  OVERLAY,
}

import { SocketService } from 'src/app/services/socket.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  sidebarActive = false;

  activeTabIndex = "";

  layoutMode: MenuOrientation = MenuOrientation.OVERLAY;

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  rotateMenuButton: boolean;

  sidebarClick: boolean;

  topbarItemClick: boolean;

  menuButtonClick: boolean;

  activeTopbarItem: any;

  documentClickListener: Function;

  
  onlineStatus: boolean = navigator.onLine;

  suscriptionWssChat: Subscription;

  statusNotificationDesktop = true;
  constructor(
    public renderer: Renderer2,
    private wssService: SocketService,
    private globalService: GlobalService,
    private router: Router
  ) {
    window.addEventListener("blur", () => {
      this.statusNotificationDesktop = false;
      /* console.log("leave") */
    });

    window.addEventListener("focus", () => {
      this.statusNotificationDesktop = true;
      /* console.log("return") */
    }); 
    this.suscriptionWssChat = this.wssService.chatMessage$.subscribe(data => {
      if(data){
        switch (data['action']) {
          case "chatNotificationNew":
            let objSend = { 
              id: uuid.v4(), 
              title: data['message']['title'], 
              message: data['message']['message'], 
              image: data['message']['image'] , 
              timeout: 12000 , 
              type: 'NOTIFY',
              url: data['message']['url']
            };
            if(data['message']['typeMessage'] === "IMAGE"){
              objSend['message'] = `<i class="fas fa-images"></i>`;
            }
            
            if(data['message']['typeMessage'] === "VIDEO"){
              objSend['message'] = `<i class="fas fa-film"></i>`;
            }
            
            if(data['message']['typeMessage'] === "FILE"){
              objSend['message'] = `<i class="fas fa-file"></i>`;
            }
            this.globalService.sendRequest(objSend);
            // if (this.isDesktop() && !this.statusNotificationDesktop) {
            //   const options = {
            //     body: data['message']['message'],
            //     icon: data['message']['image'],
            //   };
            //   new Notification(data['message']['title'], options);
            //   // notificationPush.onclick = function (event) {
            //   //   event.preventDefault();
            //   //   window.location.replace(data['message']['url']);
            //   // }
            // }
            let audio: HTMLVideoElement =document.getElementById("audioChat") as HTMLVideoElement;
            audio.play();
            break;
          case "notificationNew":
            if(data['list'] && data['list'].length > 0){
              this.globalService.sendRequest({ type: 'UPDATENOTIFICATIONS' });
              for (const notification of data['list']) {
                this.globalService.sendRequest({ 
                  id: uuid.v4(), 
                  title: notification['title'], 
                  message: this.globalService.lang === "en" ? notification['messageEn'] : notification['messageEs'], 
                  image: notification['image'] , 
                  timeout: 12000,
                  url: notification['url'] , 
                  type: 'NOTIFY'});
                  // if (this.isDesktop() && !this.statusNotificationDesktop) {
                  //   const options = {
                  //     body: this.globalService.lang === "en" ? notification['messageEn'] : notification['messageEs'],
                  //     icon: notification['image'],
                  //   };
                  //   new Notification(notification['title'], options);
                  //   // notificationPush.onclick = function (event) {
                  //   //   event.preventDefault();
                  //   //   window.location.replace(notification['url']);
                  //   // }
                  // }
              }
              
              let audio: HTMLVideoElement =document.getElementById("audioNotify") as HTMLVideoElement;
              audio.play();
            }
            break;
          default:
            break;
        }
      }
    });

    /* this.wssService.message.subscribe(notification => {
      if(notification){
        console.log(notification);
        if(notification['action'] === "blockedAccount"){
        }else{
          this.globalService.sendRequest({ type: 'UPDATENOTIFICATIONS' });
          this.globalService.sendRequest({ type: 'STATUSCOMPLETEDBUSINESS' });
        }
      }
    }); */

  }

  ngOnInit(): void {
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        console.log("notificaciones aceptadas");
      }else{
        console.log("notificaciones denegadas");
      }
    });
    if(this.onlineStatus){
      this.wssService.connectionWebSocket();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // actualizar las url en tiempo real
        this.wssService.updateRouteRA();
      }
    });
  }

  @HostListener('window:online', ['$event'])

  onOnline(event: Event) {
    this.onlineStatus = true;
    console.log('Conexión restablecida');
    //aqui cerrar conexion anterior y reniciar conexion
    this.wssService.connectionWebSocket();
    // this.globalService.sendRequest({ type: 'CONNECTION_RESTORED' });
    // if(this.globalService.desktop){
    //   this.globalService.sendRequest({ 
    //     id: uuid.v4(), 
    //     message: 'CONNECTION_RESTORED', 
    //     icon: 'fas fa-wifi' , 
    //     timeout: 0,
    //     type: 'NOTIFY_SYSTEM'});
    // }
  }

  @HostListener('window:offline', ['$event'])
  onOffline(event: Event) {
    this.onlineStatus = false;
    console.log('Conexión perdida');
    this.globalService.sendRequest({ type: 'LOST_CONNECTION' });
    // if(this.globalService.desktop){
    //   this.globalService.sendRequest({ 
    //     id: uuid.v4(), 
    //     message: 'LOST_CONNECTION', 
    //     icon: 'fas fa-wifi' , 
    //     timeout: 0,
    //     type: 'NOTIFY_SYSTEM'});
    // }
  }

  ngAfterViewInit() {
    this.documentClickListener = this.renderer.listen(
      'body',
      'click',
      (event) => {
        if (!this.topbarItemClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
        }

        if (
          !this.menuButtonClick &&
          !this.sidebarClick &&
          (this.overlay || !this.isDesktop())
        ) {
          this.sidebarActive = false;
        }

        this.topbarItemClick = false;
        this.sidebarClick = false;
        this.menuButtonClick = false;
        
      }
    );
  }

  onTabClick(event: Event, index: string) {
    if (this.activeTabIndex === index) {
      this.sidebarActive = !this.sidebarActive;
    } else {
      this.activeTabIndex = index;
      this.sidebarActive = true;
    }

    event.preventDefault();
  }

  closeSidebar(event: Event) {
    this.sidebarActive = false;
    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.sidebarClick = true;
  }
  
  onMenuButtonClick(event: Event, index: number) {
    this.menuButtonClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;
    this.sidebarActive = !this.sidebarActive;

    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    if (this.activeTabIndex = "") {
      this.activeTabIndex = "";
    }

    event.preventDefault();
  }

  onTopbarItemClick(event: Event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSearchItemClick(event: Event) {
    this.topbarItemClick = true;

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  get overlay(): boolean {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    this.wssService.closeWebSocket();
    this.suscriptionWssChat?.unsubscribe();
  }
}
