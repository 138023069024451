<div class="grid m-0">
    <div class="col-12 p-0">
        <div class="card flex align-items-center" style="height: calc(var(--doc-height) - 32px);">
            <div class="grid m-0 w-full text-center background-errp">
                <div class="col-12">
                    <img src="assets/images/cbn_robot.png" alt="" srcset="">
                </div>
                <div class="col-12">
                    <h1 translate>we_re_sorry</h1>
                </div>
                <div class="col-12">
                    <h3 translate>we_re_sorry_text</h3>
                    <button type="submit" class="w-button p-button-rounded" label="{{ 'BACK'  | translate }}" pButton onclick="window.history.back()"></button>
                    <h4 translate>we_re_sorry_text_s <a style="color: var(--primary-color);" href="mailto:soporte@cbntec.com" >soporte&#64;cbntec.com</a> </h4>
                </div>
            </div>
        </div>
    </div>
</div>
