import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard  {

  constructor(private sessionService : SessionService){ }

  canActivate() {
    if(this.sessionService.statusSession){
      return true;
    }else{
      let url = window.location.href;
      // localStorage.setItem('url',url)
      this.sessionService.deletedSession();
      window.location.href = "/"
      return false;
    }
  }
  
}
