<div class="grid m-0 scrollStyle" style="height:calc(var(--doc-height) - 62px) ; overflow-x: hidden; overflow-y: auto;">
    <div class="col-12 p-0">
        <div class="card mb-0">
            <div class="grid formgrid"> 
                <div class="col-12 field">
                    <label>
                        <input type='checkbox' [checked]='calendarOptions().weekends'
                            (change)='handleWeekendsToggle()' />
                        Ocultar fines de semana
                    </label>
                </div>
                <div class="col-12 field">
                    
                </div>
                <div class="col-12 field">
                    <full-calendar *ngIf='calendarVisible()' [options]='calendarOptions()' locale="es">
                        <ng-template #eventContent let-arg>
                            <b>{{ arg.timeText }}</b>
                            <i>{{ arg.event.title }}</i>
                        </ng-template>
                    </full-calendar>
                </div>
                <div class="col-12 field">
                    <p-button label="Save" [outlined]="true" severity="secondary" (onClick)="saveAll()" />
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Evento" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
    <div class="grid formgrid">
        <div class="field col-12">
            <label class="font-bold">Titulo del evento</label>
            <input pInputText [(ngModel)]="title" class="w-full" />
        </div>
        <div class="field col-12">
            <label class="font-bold">Color del evento</label>
            <div [style.background-color]="colorName" [cpPosition]="'left'" [cpToggle]="true"
                [cpDialogDisplay]="'inline'" [(colorPicker)]="colorName" class="color-picker"></div>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" [text]="true" severity="secondary" (onClick)="visible = false" />
        <p-button label="Save" [outlined]="true" severity="secondary" (onClick)="saveEvent()" />
    </ng-template>
</p-dialog>