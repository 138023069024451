import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ChangeDetectorRef} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { GlobalService } from './services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SessionService } from 'src/app/services/session.service';
import { SessionIn } from 'src/app/interfaces/session-in';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
declare let gtag: (property: string, value: any, configs: any) => {};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  sessionIn: SessionIn;
  expiredSession = false;
  BlockAccountShow = false;
  load: boolean = false;
  updatePlatForm = false;
  newVersionSubscription: Subscription;
  subscriber: Subscription;
  constructor(
    private primengConfig: PrimeNGConfig,
    private globalService: GlobalService,
    public trans: TranslateService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private changeDetector: ChangeDetectorRef,
    private swUpdate: SwUpdate,
    public router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId, {
          page_path: event.urlAfterRedirects
        });
      }
    });

    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'TOAST':
            this.showToast(res);
            break;
          case 'LOAD':
            this.showLoad(res);
            break;
          case 'BLOCKED_ACCOUNT':
            this.BlockAccountShow = true;
            break;
          default:
            break;
        }
      }
    });
    this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
      console.log(evt)
        switch (evt.type) {
            case 'VERSION_DETECTED':
                console.log(`Downloading new app version: ${evt.version.hash}`);
                break;
            case 'VERSION_READY':
                console.log(`Current app version: ${evt.currentVersion.hash}`);
                console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                this.updatePlatForm = true;
                break;
            case 'VERSION_INSTALLATION_FAILED':
                console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                break;
            default:
                break;
        }
    });
  }

  ngOnInit() {
    this.set_initial_setup();
    this.primengConfig.ripple = true;
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
    this.globalService.validateDevice(); 
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.newVersionSubscription?.unsubscribe();
  }

  clickUopdate(){
    window.location.reload();
    this.updatePlatForm = false;
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    //fix tables height primeng 17
    let wrapperElements: HTMLCollection = document.getElementsByClassName('p-datatable-wrapper');
        Array.prototype.forEach.call(wrapperElements, function(wrapperElement) {
            let maxHeight = wrapperElement.style["max-height"];
            if (maxHeight) {
                wrapperElement.style["height"] = maxHeight;
            }
    });
  }


  set_initial_setup() {
    let theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';
    localStorage.setItem('theme', theme);
    this.globalService.switchTheme(theme);

    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es';
    localStorage.setItem('lang', lang);
    this.globalService.changeLanguage(lang);
  }

  async showToast(data) {
    let detail = await new Promise((resolve, reject) => {
      this.trans.get(data['detail']).subscribe((res) => {
        resolve(res);
      });
    });

    let summary = await new Promise((resolve, reject) => {
      this.trans.get(data['summary']).subscribe((res) => {
        resolve(res);
      });
    });
    if (data['detail'] === 'TOKEN_REVOKED' || data['detail'] === "TOKEN_UNAUTHORIZED") {
      this.expiredSession = true;
    }else{
      let messageToast: any = {
        severity: data['severity'],
        summary: summary,
        detail: detail,
        life: 5000,
        sticky: data['sticky'] ? data['sticky'] : false
      };
      this.messageService.add(messageToast);
    }
  }

  async signOff() {
    let url = window.location.href;
    localStorage.setItem('url',url)
    this.sessionService.deletedSession();
    window.location = window.location;
  }

  showLoad(data) {
    if (data['status']) {
      this.load = true;
    } else {
      this.load = false;
    }
  }
}
