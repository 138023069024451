<ng-container *ngIf="showRoute">
    <router-outlet></router-outlet>
</ng-container>


<p-sidebar [(visible)]="show" position="right" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false"
    styleClass="sidebar-create-business-responsive" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center" style="border-bottom: 1px solid var(--surface-d); height: 53px;">
                <div class="col-12 ">
                    <h2 class="m-0 text-center">Detalle del nivel de informacion</h2>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 177px)'}">
                <div class="grid m-0">
                    <div class="col-12 pb-2" style="border-bottom: 1px solid var(--surface-d);">
                        <div class="grid m-0">
                            <div class="col-6 text-center" style="border-right: 1px solid var(--surface-d);">
                                <p style="margin-top: 30px;">Verificacion CBN</p>
                                <p>Verificacion terceros</p>
                                <p>Comercio seguro</p>
                            </div>
                            <div class="col-6 text-center">
                                <img style="width: 100px;"
                                    src="https://cbn-files-business.s3.amazonaws.com/e438b152886c72fe50ad90b7cec3f7e2/profile/logo/a734a1dc-5f4e-4841-8258-44ff303fbdb4.png"
                                    alt="" srcset="">
                                <h3 class="mb-0">ULTRA MUSIC FESTIVAL</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="border-bottom: 1px solid var(--surface-d);">
                        <div class="grid m-0">
                            <div class="col-12">
                                <h4 class="m-0 text-center">PUNTAJE ACUMULADO POR SECCION</h4>
                            </div>
                            <div class="col-12 p-0">
                                <div class="grid m-0">
                                    <div class="col-6 text-right" style="border-right: 1px solid var(--surface-d);">
                                        35/35
                                    </div>
                                    <div class="col-6">
                                        Informacion basica
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="grid m-0">
                            <div class="col-12">
                                <h4 class="m-0 text-center">DETALLE DE PUNTAJE POR SECCION</h4>
                            </div>
                            <div class="col-12">
                                <div class="grid m-0">
                                    <div class="col-6 text-right" style="border-right: 1px solid var(--surface-d);">
                                        35/35
                                    </div>
                                    <div class="col-6">
                                        Informacion basica
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-scrollPanel>
        </div>
        <div class="col-12 pb-0">
            <button pButton pRipple type="button" label="{{'closeU' | translate }}"
                class="w-button p-button-rounded p-button-danger" (click)="show = false"></button>
        </div>
    </div>
</p-sidebar>