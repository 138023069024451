import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard  {

  constructor(
    private aws : AwsSharedService,
    private router: Router
  ){ }

  async canActivate(route: ActivatedRouteSnapshot) {
    let result = await firstValueFrom(this.aws.getStatusCompany(route.params['company']));
    if(result['status'] && result['data'] === true){
      return true;
    }else{
      this.router.navigate(['/home']);
      return false;
    }
  }
  
}
