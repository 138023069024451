import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  show = false;
  subscriber: Subscription;
  idCompany = "";
  idHub = "";
  showRoute = true;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {

   }

  ngOnInit(): void {
    this.subscriber = this.activatedRoute.params.subscribe((params) => {
      if(this.idCompany != params['company']){
        this.idCompany = params['company'];
        this.showRoute = false;
        setTimeout(() => {
          this.showRoute = true
        }, 100);
        
      }
    });
  }

  ngOnDestroy(): void {
  }
}
