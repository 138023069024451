import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class SunatService {

  private tokenUrl = 'https://api-seguridad.sunat.gob.pe/v1/clientesextranet/be0abf12-bf32-469b-b273-3381121d8d5c/oauth2/token/';
  private clientId = 'be0abf12-bf32-469b-b273-3381121d8d5c';
  private clientSecret = 'OwCuJCePdV/uCS3VX5cuEw==';
  private scope = 'https://api.sunat.gob.pe/v1/contribuyente/contribuyentes';
  private grantType = 'client_credentials';

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks',
      'Auth': this.sessionService.token
    })
  };

  xmlToJson(xml: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'application/xml');
    return this.xmlToJsonRecursive(xmlDoc);
  }

  private xmlToJsonRecursive(xml: any): any {
    let obj: any = {};

    if (xml.nodeType === 1) { // element
      if (xml.attributes.length > 0) {
        obj['@attributes'] = {};
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) { // text
      obj = xml.nodeValue;
    }

    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i);
        const nodeName = item.nodeName;
        if (typeof (obj[nodeName]) === 'undefined') {
          obj[nodeName] = this.xmlToJsonRecursive(item);
        } else {
          if (typeof (obj[nodeName].push) === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJsonRecursive(item));
        }
      }
    }
    return obj;
  }

  //LINK EXTERNO

  getTokenSunat(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new HttpParams()
      .set('grant_type', this.grantType)
      .set('scope', this.scope)
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);

    return this.http.post(this.tokenUrl, body.toString(), { headers }); 
  }
  
  consultSunat(body:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks'
    });

    return this.http.post(`${environment.baseUrlAPI}/consultSunat`, body, { headers }); 
  }

}

