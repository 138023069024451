import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, firstValueFrom } from 'rxjs';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { AwsSharedService } from 'src/app/services/main/shared/aws-shared.service';
import { CompanyService } from 'src/app/services/company.service';
import { GlobalService } from 'src/app/services/global.service';
import { HubService } from 'src/app/services/hub.service';
@Component({
  selector: 'app-home-sidebar',
  templateUrl: './home-sidebar.component.html',
  styleUrls: ['./home-sidebar.component.scss']
})
export class HomeSidebarComponent implements OnInit, OnDestroy {
  tabs: any = [];
  bodies: any = [];
  selectedTab = null;

  idCompany = "";
  idHub = "";

  sidebarType = "USER";

  subscriberGlobal: Subscription;
  subscriber: Subscription;
  companies = []
  constructor(
    public app: HomeComponent,
    private globalService: GlobalService,
    private companyService: CompanyService,
    private hubService: HubService,
    private aws: AwsSharedService,
    private router: Router
  ) {
    this.subscriberGlobal = this.globalService.request.subscribe(res => {
      if(res){
        switch (res['type']) {
          case 'OPENSIDEBARMOBILE':
            this.addMenu();
            break;
          case 'CLOSESIDEBARMOBILE':
            this.removeMenu();
            break;
          default:
            break;
        }
      }
    });

    this.subscriber = router.events.pipe( filter(event => event instanceof NavigationEnd) ).subscribe((event) => {
      //validar si es la misma tipo de ruta
      let typeRouteNew = this.getTypeUser();
      if(this.sidebarType != typeRouteNew){
        this.refreshSidebar();
        this.globalService.sendRequest({ type: 'UPDATE_COUNT_CHAT' });
      }else{
        if(typeRouteNew === "COMPANY"){
          if(this.idCompany != this.companyService.idCompany){
            this.refreshSidebar();
            this.globalService.sendRequest({ type: 'UPDATE_COUNT_CHAT' });
          }
        }
        if(typeRouteNew === "HUB"){
          if((this.idCompany != this.hubService.idCompany) || (this.idHub != this.hubService.idHub)){
            this.refreshSidebar();
            this.globalService.sendRequest({ type: 'UPDATE_COUNT_CHAT' });
          }
        }
      }
    });
   }

  ngOnInit(): void {
    this.refreshSidebar();
    this.getCompaniesAll();
  }

  ngOnDestroy(): void {
    this.subscriberGlobal?.unsubscribe();
    this.subscriber?.unsubscribe();
  }

  async getCompaniesAll(){
    this.companies = [];
    let result = await firstValueFrom(this.aws.getCompaniesVerified());
    if(result && result['status'] === true){
      this.companies = result['data'].filter(c => c.logo && c.logo.length > 0 ).map(c => {
        return {
          id: c['id'],
          logo: c['logo'],
          businessName: c['businessName']
        }
      });
    }
  }

  selectedTabClick($event, tab){
    this.app.onTabClick($event, tab.id);
    this.selectedTab = tab;
  }

  async refreshSidebar(){
    this.idCompany = "";
    this.idHub = "";
    this.sidebarType = this.getTypeUser();
    if(this.sidebarType === "COMPANY"){
      this.createSidebarCompany();
    }else if(this.sidebarType === "HUB"){
      this.createSidebarHub();
    }else{
      this.createSidebarUser();
    }
  }

  getTypeUser(){
    let sidebarType = "USER";
    let paths = this.router.url.split("/").filter(c => c.length > 0 );
    if(paths.length > 0){
      if(paths[1] === "company"){
        if(this.companyService.idCompany.length > 0){
          sidebarType = "COMPANY";
        }
      }else if(paths[1] === "hub"){
        if(this.hubService.idCompany.length > 0 && this.hubService.idHub.length > 0){
          sidebarType = "HUB";
        }
      }
    }
    return sidebarType;
  }

  async createSidebarCompany(){
    this.tabs = [];
    this.bodies = [];
    this.idCompany = this.companyService.idCompany;
    let result = await firstValueFrom(this.aws.getPlanUserCompany({ idCompany: this.idCompany }));
    let data = await firstValueFrom(this.companyService.getPlans('premium'));
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      this.sidebarType = "COMPANY";
      this.tabs = data['tabs'].filter( c => ['RED'].concat(result['data']['tabs']).includes(c['id']) )
      let sections = data['sections'].filter( c => ['HUB','SEGMENT'].concat(result['data']['sections']).includes(c['id']) );
      let subSections = data['subSections'].filter( c => result['data']['subSections'].includes(c['id']) );
      let bodies = [];
      sections.forEach(section => {
        let objTemp = JSON.parse(JSON.stringify(section));
        if(subSections.filter(c => c['idSection'] === section['id']).length > 0){
          objTemp['subSections'] = subSections.filter(c => c['idSection'] === section['id']).sort((a,b) => a.order > b.order ? 1 : -1)
        }
        bodies.push(objTemp)
      });

      let red = [{
        "idTab": "RED",
        "order": 0,
        "icon": "fas fa-home",
        "id": "HOME",
        "href": "",
        "enabled": true
      }]
     
      this.bodies = red.concat(bodies)
      this.globalService.sendRequest({ type: "SIDEBAR_TYPE_REFRESH", status: "COMPANY" });
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
  }

  async createSidebarUser(){
    this.tabs = [
      {
        "icon": "fas fa-globe-americas",
        "id": "RED",
        "order": 0
      },
      {
        icon: 'fas fa-cogs',
        id: 'SETTINGS',
        order: 1,
      }
    ];
  
    this.bodies = [
      {
        "idTab": "RED",
        "order": 0,
        "icon": "fas fa-home",
        "id": "HOME",
        "href": "",
        "enabled": true
      },
      {
        "idTab": "SETTINGS",
        "order": 0,
        "icon": "fas fa-id-card-alt",
        "id": "MY_ACCOUNT",
        "href": "my-account",
        "enabled": true
      }
    ];
    this.globalService.sendRequest({ type: "SIDEBAR_TYPE_REFRESH", status: "USER" });
  }

  async createSidebarHub(){
    this.idCompany = this.hubService.idCompany;
    this.idHub = this.hubService.idHub;
    this.sidebarType = "HUB";
    this.globalService.sendRequest({ type: "SIDEBAR_TYPE_REFRESH", status: "HUB" });
    let body = { idCompany: this.hubService.idCompany, idHub: this.hubService.idHub };
    let result = await firstValueFrom(this.aws.dependencyCompanyHub(body));
    if (result['status'] === false) {
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: result['message'], type: 'TOAST', });
    } else if (result['status'] === true) {
      let hubCompany = result['data']['company'];
      if(hubCompany['type'] === "ASSOCIATED"){
        this.tabs = [
          {
            "icon": "fas fa-globe-americas",
            "id": "RED",
            "order": 0
          },
          {
            "icon": "fas fa-chart-line",
            "id": "EVALUATIONS_HUB",
            "order": 1
          }
        ];
      
        this.bodies = [
          {
            "idTab": "RED",
            "order": 0,
            "icon": "fas fa-home",
            "id": "HOME",
            "href": "",
            "enabled": true
          },
          {
            "idTab": "RED",
            "order": 1,
            "icon": "fa-broadcast-tower fas",
            "id": "HOME_HUB",
            "href": "",
            "enabled": true
          },
          {
            "idTab": "RED",
            "order": 2,
            "icon": "fab fa-buffer",
            "id": "SEGMENT",
            "href": "segment",
            "enabled": true
          },
          {
            "idTab": "EVALUATIONS_HUB",
            "order": 0,
            "icon": "fas fa-chart-line",
            "id": "EVALUATION_PANEL",
            "href": "evaluations",
            "enabled": true
          }
        ];
      }else{
        this.tabs = [
          {
            "icon": "fas fa-globe-americas",
            "id": "RED",
            "order": 0
          }
        ];
      
        this.bodies = [
          {
            "idTab": "RED",
            "order": 0,
            "icon": "fas fa-home",
            "id": "HOME",
            "href": "",
            "enabled": true
          },
          {
            "idTab": "RED",
            "order": 1,
            "icon": "fa-broadcast-tower fas",
            "id": "HOME_HUB",
            "href": "",
            "enabled": true
          },
          {
            "idTab": "RED",
            "order": 2,
            "icon": "fab fa-buffer",
            "id": "SEGMENT",
            "href": "segment",
            "enabled": true
          }
        ];
      }
    } else {
      this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type: 'TOAST', })
    }
  }
  
  getWitdh(score, order){
    const acBasic = parseFloat(score);
    let total = 35;
    if(order === 1){
      total = 35;
    }else if(order === 2){
      total = 60.00;
    }else if(order === 3){
      total = 5.00;
    }
    const withBarBasic = (acBasic * 100) / total;
    return withBarBasic;
  }

  addMenu(){
    document.getElementById("sidebar").classList.remove("hidden");
    document.getElementById("sidebar").classList.add("block");
  }

  removeMenu(){
    document.getElementById("sidebar").classList.remove("block");
    document.getElementById("sidebar").classList.add("hidden");
  }
}
