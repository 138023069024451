import { Component, OnInit, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  constructor() {

   }

  ngOnInit(): void {
 
  }

  ngOnDestroy(): void {
 
  }
}
