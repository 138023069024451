import { Component, OnInit } from '@angular/core';
import { SunatService } from './sunatservice';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-billed-xml',
  templateUrl: './billed-xml.component.html',
  styleUrl: './billed-xml.component.scss'
})
export class BilledXmlComponent implements OnInit {

  uploadedFiles: any[] = [];

  access_token:string;

  estadoCp = {
    "0": "NO EXISTE - COMPROBANTE NO INFORMADO",
    "1": "ACEPTADO - COMPROBANTE ACEPTADO",
    "2": "ANULADO - COMUNICADO EN UNA BAJA",
    "3": "AUTORIZADO - CON AUTORIZACIÓN DE IMPRENTA",
    "4": "NO AUTORIZADO - NO AUTORIZADO POR IMPRENTA"
  };
  estadoRuc = {
      "00": "ACTIVO",
      "01": "BAJA PROVISIONAL",
      "02": "BAJA PROV. POR OFICIO",
      "03": "SUSPENSION TEMPORAL",
      "10": "BAJA DEFINITIVA",
      "11": "BAJA DE OFICIO",
      "22": "INHABILITADO-VENT.UNICA",
  };
  condDomiRuc = {
      "00": "HABIDO",
      "09": "PENDIENTE",
      "11": "POR VERIFICAR",
      "12": "NO HABIDO",
      "20": "NO HALLADO",
  };

  DocumentTypes = {
    '01': 'FACTURA',
    '03': 'BOLETA_DE_VENTA',
    '04': 'LIQUIDACION_DE_COMPRA',
    '07': 'NOTA_DE_CREDITO',
    '08': 'NOTA_DE_DEBITO',
    'R1': 'RECIBO POR HONORARIOS',
    'R7': 'NOTA DE CREDITO DE RECIBOS'
  };

  loadData = false;
  constructor(
    private sunatService: SunatService
  ){

  }

  ngOnInit(): void {
    this.getTokenSunat();
  }

  async getTokenSunat(){
    try {
      let result = await firstValueFrom(this.sunatService.getTokenSunat());
      this.access_token = result['access_token'];
      console.log(result)
    } catch (error) {
      console.log(error);
    }
  }

  async onUpload(event) {
    this.uploadedFiles = [];
    this.loadData = true;
    for(let file of event.files) {
      const xmlContent = await this.readFileAsText(file);
      let xmlData = await this.parseXml(xmlContent);
      let result = this.requiredInvoiceFields(xmlData);
      console.log(result)
      let objFactura = {
        codComp: result['codComp'] + `${this.DocumentTypes[result['codComp']] ? " - "+this.DocumentTypes[result['codComp']] : ""}`,
        fechaEmision: result['fechaEmision'],
        monto: result['monto'],
        currency: result['currency'],
        numRuc: result['numRuc'],
        numRucClient: result['numRucClient'],
        numero: result['numero'],
        numeroSerie: result['numeroSerie'],
        statusValid: false,
        estadoCp: "",
        estadoCpDescripcion: "",
        estadoRuc: "",
        estadoRucDescripcion: "",
        condDomiRuc: "",
        condDomiRucDescripcion: "",
        observaciones: []
      };

      try {
        let data = await firstValueFrom(this.sunatService.consultSunat({
          numRuc: result['numRuc'],
          codComp: result['codComp'],
          numeroSerie: result['numeroSerie'],
          numero: result['numero'],
          fechaEmision: result['fechaEmision'],
          monto: result['monto'],
          token: this.access_token
        }));
        if(data['success']){
          objFactura['statusValid'] = true;
          objFactura['estadoCp'] = data['data']['estadoCp'],
          objFactura['estadoCpDescripcion'] = this.estadoCp[data['data']['estadoCp']],
          objFactura['estadoRuc'] = data['data']['estadoRuc'],
          objFactura['estadoRucDescripcion'] = this.estadoRuc[data['data']['estadoRuc']],
          objFactura['condDomiRuc'] = data['data']['condDomiRuc'],
          objFactura['condDomiRucDescripcion'] = this.condDomiRuc[data['data']['condDomiRuc']],
          objFactura['observaciones'] = data['data']['observaciones'] ? data['data']['observaciones'] : []
        }
      } catch (error) {
        console.log(error);
      }
      this.uploadedFiles.push(objFactura);
    }
    this.loadData = false;
  }

  async onSelect(e){
    console.log(e)
    const file: File = e.currentFiles[0];
    if (file) {
      const xmlContent = await this.readFileAsText(file);
      let xmlData = await this.parseXml(xmlContent);
      let result = this.requiredInvoiceFields(xmlData);
      console.log(result);
    }
  }

  readFileAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  parseXml(xml: string): Promise<any> {
    return this.sunatService.xmlToJson(xml);
  }

  requiredInvoiceFields(arch: any) {

    let codComp = arch['Invoice']['cac:AccountingSupplierParty']['cac:Party']['cac:PartyIdentification'] ? arch['Invoice']['cbc:InvoiceTypeCode']['#text'] : "R1";
    let numRuc = arch['Invoice']['cac:AccountingSupplierParty']['cac:Party']['cac:PartyIdentification'] ? arch['Invoice']['cac:AccountingSupplierParty']['cac:Party']['cac:PartyIdentification']['cbc:ID']['#text'] : arch['Invoice']['cac:AccountingSupplierParty']['cbc:CustomerAssignedAccountID']['#text'];
    let numRucClient = arch['Invoice']['cac:AccountingCustomerParty']['cac:Party']['cac:PartyIdentification'] ? arch['Invoice']['cac:AccountingCustomerParty']['cac:Party']['cac:PartyIdentification']['cbc:ID']['#text'] : arch['Invoice']['cac:AccountingCustomerParty']['cbc:CustomerAssignedAccountID']['#text'];
    let num = arch['Invoice']['cbc:ID']['#text'].split('-');
    let fecha = arch['Invoice']['cbc:IssueDate']['#text'].split('-');
    let monto = arch['Invoice']['cac:LegalMonetaryTotal']['cbc:PayableAmount']['#text'];
    let currency = arch['Invoice']['cac:LegalMonetaryTotal']['cbc:PayableAmount']['@attributes']['currencyID'];

    let factura = {
        numRuc: numRuc,
        numRucClient: numRucClient,
        codComp: codComp,
        numeroSerie: num[0],
        numero: parseInt(num[1]),
        fechaEmision: `${fecha[2]}/${fecha[1]}/${fecha[0]}`,
        monto: monto,
        currency: currency
    };

    return factura;
};

}
