import { Component , signal, ChangeDetectorRef, OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import * as uuid from 'uuid';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/es';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrl: './example.component.scss'
})
export class ExampleComponent implements OnInit{
  events = [];
  //prueba
  enabledRanges = [
    {
        start: new Date('2024-08-13T15:00:00'),
        end: new Date('2024-08-13T17:00:00')
    },
    {
        start: new Date('2024-08-14T00:00:00'),
        end: new Date('2024-08-14T23:59:59')
    },
    {
        start: new Date('2024-12-25T00:00:00'),
        end: new Date('2024-12-25T23:59:59')
    }
  ];
  //
  subscriber: Subscription;
  calendarVisible = signal(true);
  currentEvents = signal<EventApi[]>([]);

  calendarOptions = signal<CalendarOptions>({
    locales: [ esLocale, enLocale ],
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin
    ],
    slotDuration: '00:30:00',
    initialView: 'timeGridWeek',
    nowIndicator: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay listWeek'
    },
    slotEventOverlap: false, // Evitar superposición de eventos
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDrop: this.handleEventDrop.bind(this), // Manejar arrastre de eventos
    eventResize: this.handleEventResize.bind(this), // Manejar cambio de tamaño de eventos
    eventsSet: this.handleEvents.bind(this),
    selectAllow: this.isSelectable.bind(this),
    allDaySlot: false,
    eventSources: [
      {
        events: this.getEnabledRangesEvents(),
        color: '#14b8a64f',  // Color del evento de fondo (rango deshabilitado)
      }
    ],
  });

  visible = false;
  // form
  colorName = null;
  title = null;
  tempInfo = null;
  constructor(private changeDetector: ChangeDetectorRef, private globalService: GlobalService) {
    this.subscriber = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.calendarOptions().locale = this.globalService.lang;
            break;
            default:
            break;
        }
      }
    });

  }

  ngOnInit(): void {
    this.calendarOptions().locale = this.globalService.lang;
    // let data = {
    //   events: [
    //     {
    //         "id": "4e0a8e90-ac6d-4c64-ac83-d35ecf7aca78",
    //         "title": "prueba 1",
    //         "start": "2024-08-15T12:00:00-05:00",
    //         "end": "2024-08-15T12:30:00-05:00",
    //         "allDay": false,
    //         "color": "#9f1a1a",
    //         "rendering": "background"
    //     },
    //     {
    //         "id": "1f69b7da-3e2f-4712-a6a4-597c88ca0adb",
    //         "title": "prueba 2",
    //         "start": "2024-08-16T13:00:00-05:00",
    //         "end": "2024-08-16T16:00:00-05:00",
    //         "allDay": false,
    //         "color": "#2841da",
    //         "rendering": "background"
    //     },
    //     {
    //         "id": "1f69b7da-3e2f-4712-a6a4-597c88ca0adb",
    //         "title": "prueba 3",
    //         "start": "2024-08-17T13:00:00-07:00",
    //         "end": "2024-08-17T16:00:00-09:00",
    //         "allDay": false,
    //         "color": "#15da2d",
    //         "rendering": "background"
    //     }
    // ]
    // }
    // this.calendarOptions().eventSources.push(data);
  }

  handleWeekendsToggle() {
    this.calendarOptions.update((options) => ({
      ...options,
      weekends: !options.weekends,
    }));
  }


  isSelectable(selectInfo: DateSelectArg) {
    // Verificar si la selección está dentro de los rangos habilitados
    let isWithinRange = false;
    for (let range of this.enabledRanges) {
      if (selectInfo.start >= range.start && selectInfo.end <= range.end) {
        isWithinRange = true; // La selección está dentro de un rango habilitado
        break;
      }
    }
  
    // Verificar si la selección se solapa con algún evento existente
    let isOverlapping = false;
    for (let event of this.currentEvents().filter(event => event.id.length > 0)) {
      if (
        selectInfo.start < event.end &&
        selectInfo.end > event.start
      ) {
        isOverlapping = true; // La selección se solapa con otro evento existente
        break;
      }
    }
  
    if (isWithinRange && !isOverlapping) {
      return true; // Permitir la selección si está dentro de los rangos habilitados y no se solapa con otros eventos
    } else {
      if (!isWithinRange) {
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: "Solo se puede crear eventos dentro de los rangos habilitados.", type : 'TOAST' });
      } else if (isOverlapping) {
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: "La selección se solapa con un evento existente.", type : 'TOAST' });
      }
      return false; // Impedir la selección si está fuera de los rangos habilitados o se solapa con otros eventos
    }
  }

  getEnabledRangesEvents() {
    return this.enabledRanges.map(range => {
      return {
        start: range.start,
        end: range.end,
        display: 'background',  // Esto asegura que se muestre como fondo
      };
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.tempInfo = selectInfo;
    if (this.isSelectable(selectInfo)) {
      this.visible = true;
    }
  }

  saveEvent(){
    const calendarApi = this.tempInfo['view']['calendar'];

    calendarApi.unselect();

    if (this.title && this.colorName) {
      let objt = {
        id: uuid.v4(),
        title: this.title,
        start: this.tempInfo['startStr'],
        end: this.tempInfo['endStr'],
        allDay: this.tempInfo['allDay'],
        color: this.colorName,
        rendering: 'background'
      }
      calendarApi.addEvent(objt);
      this.resetSelected();
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log(clickInfo.event.title)

    for (let range of this.enabledRanges) {
      if (clickInfo.event.start < range.end && clickInfo.event.end > range.start) {
        this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: "No se puede crear un evento en este rango de tiempo. Está deshabilitado.", type : 'TOAST'});
        return; // Salir de la función para no crear el evento
      }
    }

    if (confirm(`Desea eliminar el evento '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
    
  }

  handleEventDrop(dropInfo) {
    // Verificar si el nuevo rango del evento está completamente dentro de los rangos habilitados
    let isWithinRange = false;
    for (let range of this.enabledRanges) {
      if (dropInfo.event.start >= range.start && dropInfo.event.end <= range.end) {
        isWithinRange = true; // El evento está dentro de un rango habilitado
        break;
      }
    }
  
    // Verificar si el nuevo rango del evento se solapa con algún evento existente
    let isOverlapping = false;
    for (let event of this.currentEvents().filter(event => event.id.length > 0)) {
      if (
        dropInfo.event.id !== event.id && // Ignorar el evento que se está moviendo
        dropInfo.event.start < event.end &&
        dropInfo.event.end > event.start
      ) {
        isOverlapping = true; // El evento se solapa con otro evento existente
        break;
      }
    }
  
    if (isWithinRange && !isOverlapping) {
      // Aquí podrías actualizar la base de datos o estado con la nueva información del evento
      this.globalService.sendRequest({ severity:'success', summary: 'Éxito', detail: "El evento se ha movido correctamente.", type : 'TOAST'});
    } else {
      // Revertir al estado anterior si el nuevo rango del evento está fuera de los rangos habilitados o se solapa con otro evento
      dropInfo.revert();
      let errorMsg = isOverlapping
        ? "No se puede mover el evento porque se solapa con otro evento."
        : "No se puede mover el evento fuera de los rangos habilitados.";
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: errorMsg, type : 'TOAST'});
    }
  }

  handleEventResize(resizeInfo) {
    // Verificar si el nuevo rango del evento está completamente dentro de los rangos habilitados
    let isWithinRange = false;
  
    for (let range of this.enabledRanges) {
      if (resizeInfo.event.start >= range.start && resizeInfo.event.end <= range.end) {
        isWithinRange = true; // El evento está dentro de un rango habilitado
        break;
      }
    }
  
    // Verificar si el nuevo rango del evento se solapa con algún evento existente
    let isOverlapping = false;
    for (let event of this.currentEvents().filter(event => event.id.length > 0)) {
      if (
        resizeInfo.event.id !== event.id && // Ignorar el evento que se está redimensionando
        resizeInfo.event.start < event.end &&
        resizeInfo.event.end > event.start
      ) {
        isOverlapping = true; // El evento se solapa con otro evento existente
        break;
      }
    }
  
    if (isWithinRange && !isOverlapping) {
      // Aquí podrías actualizar la base de datos o estado con la nueva información del evento
      this.globalService.sendRequest({ severity:'success', summary: 'Éxito', detail: "El evento se ha redimensionado correctamente.", type : 'TOAST'});
    } else {
      // Revertir al estado anterior si el nuevo rango del evento está fuera de los rangos habilitados o se solapa con otro evento
      resizeInfo.revert();
      let errorMsg = isOverlapping
        ? "No se puede redimensionar el evento porque se solapa con otro evento."
        : "No se puede redimensionar el evento fuera de los rangos habilitados.";
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: errorMsg, type : 'TOAST'});
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents.set(events);
    this.changeDetector.detectChanges(); 
  }

  resetSelected(){
    this.title = null;
    this.colorName = null;
    this.visible = false;
  }

  saveAll(){
    let events = [];
    for (let event of this.currentEvents()) {
      if(event.id.length > 0){
        let objt = {
          id: event.id,
          title: event.title,
          start: event['startStr'],
          end: event['endStr'],
          allDay: event['allDay'],
          color: event['backgroundColor'],
          rendering: 'background'
        }
        events.push(objt)
      }
    }
    console.log(events)
  }
}
