import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AwsHubHomeService } from 'src/app/services/main/hub/hub-home/aws-hub-home.service';

@Injectable({
  providedIn: 'root'
})
export class HubGuard  {
  
  constructor(
    private aws : AwsHubHomeService,
    private router: Router
  ){ }

  async canActivate(route: ActivatedRouteSnapshot){
    let result = await firstValueFrom(this.aws.getStatusCompanyHub({ idCompany: route.params['company'], idHub: route.params['hub'] }));
    if(result['status'] && result['data'] === true){
      return true;
    }else{
      this.router.navigate(['/home']);
      return false;
    }
  }
  
}
