import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  // Decide si una ruta debe ser reutilizada
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Compara las rutas y sus parámetros
    return future.routeConfig === curr.routeConfig && 
           JSON.stringify(future.params) === JSON.stringify(curr.params);
  }

  // Decide si una ruta debe ser almacenada para su posible reutilización
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false; // No almacenar rutas para reutilización
  }

  // Almacena la ruta para su posible reutilización
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // No almacenar rutas para reutilización
  }

  // Decide si una ruta almacenada debe ser reutilizada
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false; // No reutilizar rutas almacenadas
  }

  // Recupera la ruta almacenada para su reutilización
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null; // No reutilizar rutas almacenadas
  }
}