<div class="topbar clearfix" #topBar>
    <div class="grid desktop" style="margin: 0 0 0 60px;">
        <div class="col-3">
        </div>
        <div class="col-9 p-0">
            <div class="grid m-0">
                <div class="col-7 md:col-9" id="formularySearchOpen">
                    <ng-container *ngIf="showInputSearch || menuOpen">
                        <form [formGroup]="formSearch" (ngSubmit)="onSubmit()" method="GET" class="w-full">
                            <div class="block-search animate__animated animate__fadeIn cursor-pointer">
                                <div class="content-input">
                                    <div class="left">
                                        <p-multiSelect styleClass="multi-search" [displaySelectedLabel]="false" placeholder="{{ 'SEARCH_BY' | translate }}" [options]="fields" formControlName="fields" appendTo="body" [style]="{width: '150px'}" (onClick)="menuBtnClick = true;">
                                        </p-multiSelect>
                                    </div>
                                    <span class="p-input-icon-right w-full">
                                        <input type="text" formControlName="query" pInputText placeholder="{{ 'search_service' | translate }}" />
                                        <button pButton pRipple type="submit" icon="fas fa-search" class="p-button-rounded p-button-secondary p-button-text"></button>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>
                <div class="col-5 md:col-3">
                    <ul class="topbar-menu fadeInDown">
                        <li>
                
                            <div *ngIf="!menuOpen" class="flex align-items-center justify-content-center search-after cursor-pointer animate__animated animate__fadeIn"
                                (click)="menuOpen = true; menuBtnClick = true;">
                                <i class="fas fa-search" pTooltip="{{'searchU' | translate}}" tooltipPosition="bottom"></i>
                            </div>
                            
                        </li>
                        <li>
                            <i class="fas fa-store" (click)="business=true"  pTooltip="{{'COMPANIES' | translate}}" tooltipPosition="bottom"></i>
                        </li>
                        <li (click)="goChat()">
                            <i *ngIf="quantityNotificationsChat > 0" class="fas fa-comments" pTooltip="CHAT" tooltipPosition="bottom" pBadge [value]="quantityNotificationsChat" severity="danger"></i>
                            <i *ngIf="quantityNotificationsChat === 0" class="fas fa-comments" pTooltip="CHAT" tooltipPosition="bottom" ></i>
                        </li>
                        <li (click)="openNotifications()">
                            <i *ngIf="quantityNotifications > 0" class="fas fa-bell" pBadge [value]="quantityNotifications"
                                severity="danger"  pTooltip="{{'NOTIFICATIONS' | translate}}" tooltipPosition="bottom"></i>
                            <i *ngIf="quantityNotifications === 0" class="fas fa-bell"  pTooltip="{{'NOTIFICATIONS' | translate}}" tooltipPosition="bottom"></i>
                        </li>
                        <li class="profile-item" (click)="op.toggle($event)">
                            <a class="cursor-pointer">
                                <div class="profile-image">
                                    <p-avatar image="{{sessionIn.avatar}}?e={{logo_ramdon}}" styleClass="p-mr-2 bg-white" size="large"
                                        shape="circle"></p-avatar>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="grid m-0 mobile">
                <div class="col-12 flex align-items-center justify-content-between">
                    <div class="flex align-items-center menu-button">
                        <button pButton pRipple type="button" icon="fas fa-bars" class="p-button-rounded p-button-secondary p-button-text" (click)="openMenu()"></button>
                    </div>
                    <div class="flex align-items-center">
                        <a class="cursor-pointer inline-block">
                            <i class="fas fa-search m-0" (click)="sidebarSearch = true"></i>
                        </a>
                        <a class="cursor-pointer inline-block">
                            <i class="fas fa-store" (click)="business=true;sidebarMobile=false;"></i>
                        </a>
                        <a class="cursor-pointer inline-block" >
                            <i *ngIf="quantityNotificationsChat > 0" class="fas fa-comments" pBadge [value]="quantityNotificationsChat" severity="danger" (click)="goChat(opChat, $event);sidebarMobile=false;"></i>
                            <i *ngIf="quantityNotificationsChat === 0" class="fas fa-comments" (click)="goChat(opChat, $event);sidebarMobile=false;"></i>
                        </a>
                        <a class="cursor-pointer inline-block" >
                            <i *ngIf="quantityNotifications > 0" class="fas fa-bell" pBadge [value]="quantityNotifications" severity="danger" (click)="openNotifications();sidebarMobile=false;"></i>
                            <i *ngIf="quantityNotifications === 0" class="fas fa-bell" (click)="openNotifications();sidebarMobile=false;"></i>
                        </a>
                        <a class="cursor-pointer inline-block"  style="margin-left: 25px;">
                            <div class="profile-image" (click)="sidebarMobile = true;">
                                <p-avatar image="{{sessionIn.avatar}}?e={{logo_ramdon}}" styleClass="p-mr-2 bg-white" size="large"
                                    shape="circle"></p-avatar>
                            </div>
                        </a>
                    </div>
                </div>
    </div>
</div>

<p-overlayPanel #op styleClass="overlayTopbar" [appendTo]="topBar" baseZIndex="4">
    <ng-template pTemplate>
        <div class="grid" style="width: 450px;">
            <div class="col-3">
                <div class="grid">
                    <div class="col-12 text-center">
                        <p-avatar image="{{sessionIn.avatar}}?e={{logo_ramdon}}" styleClass="p-mr-2 bg-white"
                            size="xlarge" shape="circle"></p-avatar>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="grid">
                    <div class="col-12 p-1 pr-3">
                        <p class="text-2xl m-0 text-900 font-bold white-space-nowrap text-overflow-ellipsis overflow-hidden">
                            {{sessionIn.name}} {{sessionIn.lastName}}</p>
                    </div>
                    <div class="col-12 p-1">
                        <p class="text-lg m-0">{{type | translate }}</p>
                    </div>
                    <div class="col-12 p-1">
                        <a href="mailto:{{sessionIn.email}}"
                            style="color: var(--primary-color);">{{sessionIn.email}}</a>
                    </div>
                    <div class="col-12 p-1">
                        <span class="mr-3">
                            <ng-container
                                *ngIf="sessionIn.cellPhone.length > 0 && sessionIn.cellPhonePrefix.length > 0"><i
                                    class="fas fa-mobile-alt mr-1"></i>{{sessionIn.cellPhonePrefix}}
                                {{sessionIn.cellPhone}}</ng-container>
                        </span>
                        <span>
                            <ng-container *ngIf="sessionIn.phone.length > 0 && sessionIn.phonePrefix.length > 0"><i
                                    class="fas fa-phone mr-1"></i>{{sessionIn.phonePrefix}} {{sessionIn.phone}}
                            </ng-container>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="grid">
                    <div class="col-6 p-0 flex align-items-center justify-content-center icon-theme">
                        <span class="font-bold mr-3" translate>theme</span>
                        <i class="fas fa-sun m-2" style="font-size: 18px;"></i>
                        <p-inputSwitch [(ngModel)]="themeStatus" (onChange)="themeChange()"></p-inputSwitch> <i
                            style="font-size: 18px;" class="fas fa-moon m-2"></i>
                    </div>
                    <div class="col-6 p-0 flex align-items-center justify-content-center">
                        <span class="font-bold mr-3" translate>language</span>
                        <img class="m-2" width="18" src="../../../../assets/images/icons/spain.png" alt="" srcset="">
                        <p-inputSwitch [(ngModel)]="langStatus" (onChange)="langChange()"></p-inputSwitch>
                        <img class="m-2" width="18" src="../../../../assets/images/icons/united-kingdom.png" alt=""
                            srcset="">
                    </div>
                </div>
            </div>
            <div class="col-12 p-0">
                <p-divider [style]="{margin: '0'}"></p-divider>
            </div>
            <div class="col-12 pb-0">
                <div class="grid">
                    <div class="col-6 text-center pt-2 pb-2 grey cursor-pointer" pRipple (click)="openProfile()">
                        <p class="m-0" translate><i class="fas fa-pen"></i> profile</p>
                    </div>
                    <div class="col-6 text-center pt-2 pb-2 grey cursor-pointer" pRipple
                        (click)="confirmSignOff($event)">
                        <p class="m-0" style="color: var(--pink-500);" translate>signOff</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-confirmDialog #cd rejectButtonStyleClass="p-button-text" appendTo="body">
    <ng-template pTemplate="header">
        <h3 class="m-0 uppercase" translate>confirmation</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="p-button-rounded p-button-danger w-button mr-2" type="button" pButton label="{{'no' | translate}}"
            (click)="cd.reject()"></button>
        <button class="p-button-rounded p-button-success w-button" type="button" pButton label="{{'yes' | translate}}"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<app-sidebar-create-business (closeSidebar)="closeSidebarEvent()" *ngIf="business"></app-sidebar-create-business>

<app-notifications *ngIf="notifications"></app-notifications>

<p-sidebar [(visible)]="sidebarMobile" position="right" [showCloseIcon]="true" [style]="{width:'95vw'}">
    <div class="grid m-0 justify-content-between flex-column" style="height: calc(var(--doc-height) - 56px);">
        <div class="col-12 p-0">
            <div class="grid m-0">
                <div class="col-12 mt-3">
                    <div class="grid text-center">
                        <div class="col-12 text-center">
                            <p-avatar image="{{sessionIn.avatar}}?e={{logo_ramdon}}" styleClass="p-mr-2 bg-white"
                                size="xlarge" shape="circle"></p-avatar>
                        </div>
                        <div class="col-12 p-1">
                            <p class="m-0 text-900 font-bold white-space-nowrap text-overflow-ellipsis overflow-hidden">
                                {{sessionIn.name}} {{sessionIn.lastName}}</p>
                        </div>
                        <div class="col-12 p-1">
                            <p class="m-0">{{type}}</p>
                        </div>
                        <div class="col-12 p-1">
                            <a href="mailto:{{sessionIn.email}}"
                                style="color: var(--primary-color);">{{sessionIn.email}}</a>
                        </div>
                        <div class="col-12 p-1">
                            <span class="mr-3">
                                <ng-container
                                    *ngIf="sessionIn.cellPhone.length > 0 && sessionIn.cellPhonePrefix.length > 0"><i
                                        class="fas fa-mobile-alt mr-1"></i>{{sessionIn.cellPhonePrefix}}
                                    {{sessionIn.cellPhone}}
                                </ng-container>
                            </span>
                            <span>
                                <ng-container *ngIf="sessionIn.phone.length > 0 && sessionIn.phonePrefix.length > 0"><i
                                        class="fas fa-phone mr-1"></i>{{sessionIn.phonePrefix}} {{sessionIn.phone}}
                                </ng-container>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="font-bold mr-3" translate>theme</span>
                    <i class="fas fa-sun m-2" style="font-size: 18px;"></i>
                    <p-inputSwitch [(ngModel)]="themeStatus" (onChange)="themeChange()"></p-inputSwitch> <i
                        style="font-size: 18px;" class="fas fa-moon m-2"></i>
                </div>
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="font-bold mr-3" translate>language</span>
                    <img class="m-2" width="18" src="../../../../assets/images/icons/spain.png" alt="" srcset="">
                    <p-inputSwitch [(ngModel)]="langStatus" (onChange)="langChange()"></p-inputSwitch>
                    <img class="m-2" width="18" src="../../../../assets/images/icons/united-kingdom.png" alt=""
                        srcset="">
                </div>
                <div class="col-12 flex align-items-center justify-content-between">
                    <button (click)="openProfile()" pButton pRipple type="button" label="{{'profile' | translate}}"
                        class="p-button-rounded p-button-raised p-button-secondary p-button-text  w-button"></button>
                    <button (click)="confirmSignOff($event)" pButton pRipple type="button" label="{{'signOff' | translate}}"
                        class="p-button-rounded p-button-raised p-button-danger p-button-text  w-button"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 flex align-items-center justify-content-between">
            <button (click)="sidebarMobile = false" pButton pRipple type="button" label="{{'closeU' | translate}}"
                class="p-button-rounded p-button-danger w-button"></button>
        </div>
    </div>

</p-sidebar>

<app-sidebar-feedback *ngIf="feedback" (closeSidebar)="feedback = false;"></app-sidebar-feedback>

<p-sidebar [(visible)]="sidebarSearch" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebar-search" [showCloseIcon]="true">
    <div class="grid m-0 search-container">
        <form [formGroup]="formSearch" (ngSubmit)="onSubmit()" method="GET" class="w-full">
            <div class="block-search animate__animated animate__fadeIn cursor-pointer mx-3">
                <div class="content-input">
                    <div class="left">
                        <p-multiSelect [filter]="true" filterBy="label" styleClass="multi-search" [displaySelectedLabel]="false" placeholder="{{ 'SEARCH_BY' | translate }}" [options]="fields" formControlName="fields" appendTo="body" [style]="{width: '135px'}" (onClick)="menuBtnClick = true;">
                        </p-multiSelect>
                    </div>
                    <span class="p-input-icon-right w-full">
                        <input type="text" formControlName="query" pInputText placeholder="{{ 'search_service' | translate }}" />
                        <button pButton pRipple type="submit" icon="fas fa-search" class="p-button-rounded p-button-secondary p-button-text"></button>
                    </span>
                </div>
            </div>

        </form>
        
    </div>
</p-sidebar>

<p-sidebar [(visible)]="chat" position="right" [baseZIndex]="10000" styleClass="sidebar-chat-responsive" [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" [blockScroll]="true">
    <div class="grid">
        <div class="col-12 mt-4">
            <div class="grid flex align-items-center" style="height: 48.96px; border-bottom: 1px solid var(--surface-d);">
                <div class="col-12">
                    <h2 class="m-0" translate>MESSAGES</h2>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
                    <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 165px)'}">
                        <ng-container *ngIf="loadCompaniesChat">
                            <div class="conversation">
                                <div class="picture">
                                    <p-skeleton shape="circle" size="3rem"></p-skeleton>
                                </div>
                                <div class="content">
                                    <div class="conversationHeader">
                                        <div class="name">
                                            <p-skeleton width="13rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                                        </div>
                                        <div class="time"> 
                                            <p-skeleton size="1rem" styleClass="mr-2"></p-skeleton>
                                        </div>
                                    </div>
                                    <div class="message">
                                        <p-skeleton width="5rem" styleClass="mb-2" borderRadius="16px"></p-skeleton>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!loadCompaniesChat">
                            <ng-container *ngFor="let participant of companiesChat">
                                <div class="conversation" (click)="goChatRedirect(participant); chat = false;">
                                    <div class="picture">
                                        <ng-container *ngIf="participant.typeChat === 'COMPANY' || participant.typeChat === 'USER_GROUP_COMPANY'">
                                            <p-avatar styleClass="avatar-chat-square" size="large" [image]="participant.avatar && participant.avatar.length > 0 ? participant.avatar : 'assets/images/user.svg'" shape="square"></p-avatar>
                                            <img *ngIf="participant.logo.length > 0" class="logo" src="{{participant.logo}}" alt="" srcset="">
                                            <div class="no-logo" *ngIf="participant.logo.length === 0">
                                                {{participant.businessName.charAt(0)}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="participant.typeChat === 'USER_DIRECT_COMPANY'">
                                            <p-avatar styleClass="avatar-chat" size="large" [image]="participant.avatar && participant.avatar.length > 0 ? participant.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
                                            <img *ngIf="participant.logo.length > 0" class="logo" src="{{participant.logo}}" alt="" srcset="">
                                            <div class="no-logo" *ngIf="participant.logo.length === 0">
                                                {{participant.businessName.charAt(0)}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="content">
                                        <div class="conversationHeader">
                                            <div class="name">{{ participant.name }}</div>
                                            <div class="time" *ngIf="participant.messagesUnread > 0"> <p-badge [value]="participant.messagesUnread"></p-badge></div>
                                        </div>
                                        <div class="message">
                                            <ng-container *ngIf="participant.typeMessageToRead === 'TEXT'">
                                                {{participant['newMessageToRead']}}
                                            </ng-container>
                                            <ng-container *ngIf="participant.typeMessageToRead === 'IMAGE'">
                                                <i class="fas fa-images"></i>
                                            </ng-container>
                                            <ng-container *ngIf="participant.typeMessageToRead === 'VIDEO'">
                                                <i class="fas fa-film"></i>
                                            </ng-container>
                                            <ng-container *ngIf="participant.typeMessageToRead === 'FILE'">
                                                <i class="fas fa-file"></i>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </p-scrollPanel>
        </div>
        <div class="col-12 px-0 pb-0">
            <ng-container *ngIf="!loadCompaniesChat && typeRedirectChat === 'COMPANY'">
                <button pButton pRipple type="button" label="{{ 'go_to_chat' | translate }}" class="p-button-rounded w-button p-button-secondary mr-2" (click)="goChatCompany();chat = false;"></button>
            </ng-container> 
            <button pButton pRipple type="button" label="{{'closeU' | translate}}" class="p-button-rounded w-button p-button-danger" (click)="chat = false;"></button>
        </div>
    </div>
</p-sidebar>
