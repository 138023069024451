import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss']
})
export class HubComponent implements OnInit, OnDestroy {
  subscriber: Subscription;
  idHub = "";
  showRoute = true;
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriber = this.activatedRoute.params.subscribe((params) => {
      if(this.idHub != params['hub']){
        this.idHub = params['hub'];
        this.showRoute = false;
        setTimeout(() => {
          this.showRoute = true
        }, 100);
        
      }
    });
  }

  ngOnDestroy(): void {
  }

}
